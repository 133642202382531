/* -------------------------------- 

swipe

-------------------------------- */

.swipe {
	position: absolute;
	.outer-circle, .inner-circle {
		border-radius: 50%;
		height: 32px;
		width: 32px;
		position: absolute;
		left: 29px;
		visibility: hidden;
		opacity: 0;
	}
	.outer-circle {
		z-index: 1000;
		background: white;
	}
	.inner-circle {
		z-index: 1100;
		background: red;
	}

}

.swipe-mobile {
	position: relative;
	// height: 100%;
	// width: 100%;
	// flex-grow: 1;
	// display: flex;
	// flex-direction: column;
	.swipe-mobile-inner {
		flex-grow: 1;
		height: 45px;
		width: 100%;
		background: red;
		position: absolute;
		visibility: hidden;
		opacity: 0;
	}
}