

/********************
CUSTOM CSS
********************/


/***** helpers *****/

.vh-align-parent {
    /*to eliminate blurryness for "half pixel" child element for vertical align*/
    transform-style: preserve-3d;
    position: absolute;
    width: 100%;
    height: 100%;
}

.vh-align-child {
    position: absolute;
    top: 50%;
    //left: calc(50% - 120px);
    left: 50%;
    transform: translate(-50%, -50%);
}

.is-hidden {
  visibility: none;
  opacity: 0;
}

/***** Mixins *****/

@mixin blueprint-background() {
  background-color:$black;
  background-image: 
    linear-gradient(rgba(255,255,255,.1) 1px, transparent 0px),
    linear-gradient(90deg, rgba(255,255,255,.1) 1px, transparent 0px),
    linear-gradient(rgba(255,255,255,.2) 1px, transparent 0px),
    linear-gradient(90deg, rgba(255,255,255,.1) 1px, transparent 0px);
  background-size:50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position:-1px -1px, -1px -1px, -1px -1px, -1px -1px
}

@mixin border() {
&::before {
  content: " ";
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    border: solid 1px rgba(255,255,255,0.3);
    position: absolute;
    z-index: 999999;
    margin: 20px;
    top: 0;
    left: 0;
}
}

@mixin maintain-ratio($ratio: 1 1) {
  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1)); 
  width: $width;
  height: 0;
  padding-bottom: $height;
}

/***** CBC GLobal Nav *****/

#cbc-globalnav {
    border-top: 1px solid #1a1a1a;
    border-bottom: 1px solid #1a1a1a;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999999999;
    width: 100%;
}

/***** layout*****/

*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
   //prevent scroll on iphone
   height: 100%;
   width: 100%;
   overflow: hidden !important;
   position: relative !important;
   position: fixed;

   @include font-smoothing;
}

//make all wrappers full page
.site-container, .app, .app > div, 
.app > div > .component-wrapper,
.app > div > .component-wrapper > div,
.page-wrapper-outer
{
  height: 100%;
  width: 100%;
}


/***** Colors *****/

$red: #E60605;


/***** Typography*****/

body {
	color:$white;
	background-color:$black;
	font-family: "acumin-pro", sans-serif;
	font-weight:400;
	font-size:12px;
	line-height:1.3;
	@media (min-width: 768px) {font-size: 16px;}
	@media (min-width: 1600px) {font-size: 18px;}
}
h1, h2, h3, h4, h5, h6 {
	font-family: "acumin-pro-condensed", sans-serif;
	font-weight:800;}

ul, ol, li, p {line-height:1.3;}

strong {font-weight:800;}



/***** Video Player Control *****/

.video-player {
  margin: 1em auto;
  display: block;
  position: relative;
  max-width: 100%;
  
  &:hover {
   + .video-control {
     opacity: 0.8;
   }
  }
}
a.video-control {
  font-size: 4em;
  opacity: 0.5;
  transition: opacity 0.3s;
  color: $red;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:hover {opacity:1;}
	&:before, &:after {
    position: absolute;
    // width: 1em;
    // height: 1em;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin: -0.5em 0 0 -0.5em;
    
  }
  //play button
  // &:before {
  //   content: '\25BA';
  //   transition: opacity 0.3s;
  // }
  .play-icon {
  	position: absolute;
  	display: block;
  	width: 1em;
  	height: 1em;
  	top: 50%;
  	left: 50%;
  	margin: -0.5em 0 0 -0.5em;
  	background: url(/assets/img/play-icon.png) no-repeat center;
  	background-size: contain;
  }

  &.hidden {
  	.play-icon {
	  	opacity: 0;
	  	transition: opacity 0.3s;
	  }
  }
  &.playing {
  	.play-icon {
	  	opacity: 0;
	  }
  }
  //pause button
  &:after {
    content: 'll';
    font-weight: 700;
  }
  &.paused {
    &:after {
      opacity: 0;
    }
  }
  &.playing {
	  &:after {
		  opacity:0;
	  }
  }
}


/***** Slides *****/

.slide {
    width: 100vw;
  	height: 100vh;
    overflow: hidden;
    position:relative;
	@media (min-width: 768px) {
		&:before {
		content:"";
		position:absolute;
		bottom:30px;
		right:30px;
		width:20px;
		height:20px;
		background: url(/assets/img/cbc-gem-white.svg) no-repeat center;
		background-size:20px;
		z-index: 99999;
		}
	}
	&.vid-compare:before, &.hero:before {display:none;}
	&.quote:before {background: url(/assets/img/cbc-gem-red.svg) no-repeat center;}
}


/********************
Loader
********************/

.loader-bg {
	// position: absolute;
	// width: 100vw;
	// height: 100vh;
	// z-index: 10;
}

.loader-initial {
  visibility: visible;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 48%;
  z-index: 10;
  .line {
  	margin: 0;
  }
}

.loader {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 48%;
  z-index: 10;
}

.line {
  animation: expand 1s ease-in-out infinite;
  border-radius: 10px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 2px;
  width: 1px;
  height: 16px;
}

.line:nth-child(1) {
  background: darken($dark-gray,35%);
}

.line:nth-child(2) {
  animation-delay: 180ms;
  background: $red;
}

.line:nth-child(3) {
  animation-delay: 360ms;
  background: $white;
}

.line:nth-child(4) {
  animation-delay: 540ms;
  background: $red;
}

.line:nth-child(5) {
  animation-delay: 720ms;
  background: darken($dark-gray,35%);
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
}

/********************
Nav
********************/

@-webkit-keyframes pulse {
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(2); }
    150% { -webkit-transform: scale(1); }
}
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(2); }
    150% { transform: scale(1); }
}

.nav{
  	position:fixed;
	z-index:99999999;
  	top:50%;
  	right:15px;
  	transform:translateY(-50%);
	@media (min-width: 768px) {right:30px;}
	.dot {
		display:block;
      	border:1px solid $white;
      	background:$red;
      	margin-bottom:16px;
      	border-radius:50%;
      	width:8px;
      	height:8px;
      	cursor:pointer;
      	color:$white;
      	text-decoration:none;
		position:relative;
		opacity:0.6;
		&:first-child {
			animation-name: pulse;
			animation-duration: 0.66s;
			animation-iteration-count: 5;
			animation-delay: 0.3s;
			animation-timing-function: ease-out; 
		}
		
		&:hover, span{transition:all 200ms ease; opacity:1}
		&:hover, &.active {background:$red;transform:scale(1.4);}
		span {
        	font-size:8px;
        	display:block;
			text-transform:uppercase;
        	transform:translate(-12px, -2px);
        	opacity:1;
			position: absolute;
    		right: 0;
			color:$medium-gray;
			&:hover, &.active {
          	opacity:1;
			color:$medium-gray;
          	transform:translateX(-10px);
        	}
      	}	
		&:active, &.active{transform:scale(1.8);border:solid 1px $red; background:$white;opacity:0.8; outline:none;}
		&:focus {outline:none;}

	}  
}



/********************
SLIDES
********************/

/***** Hero *****/

.hero {
	background: url(/assets/img/bg-hero-sml.jpg) no-repeat top right;
    background-size: cover;
    z-index: 2;
	@media (min-width: 768px) {background: url(/assets/img/bg-hero.jpg) no-repeat top right; background-size: cover;}
	span {
		//hide at first
		visibility: hidden;
		opacity: 0;
		font-family: "acumin-pro-condensed", sans-serif;
		font-size: 1.25em;
		font-weight:800;
    	text-transform: uppercase;
    	letter-spacing: 0.5em;
    	margin: 0 0 0.5em 0;
		text-shadow: 0 1px 7px #182a4d;
		@media (min-width: 768px) {font-size: 1.5em;}
		@media (min-width: 1600px) {font-size: 1.75em;}
		}
	h1 {
		//hide at first
		visibility: hidden;
		opacity: 0;	
	}
	p{margin:0.5em 0 0;}
	.cbc-gem {width:40%; max-width:200px; margin-top:3em;}
	
	.mouse {
	  border: 2px solid $red;
	  border-radius: 18px;
	  position:absolute; left:0; right:0; margin-left:auto; margin-right:auto; bottom:5px;
	  width: 18px;
	  height: 34px;
	  -webkit-backface-visibility: hidden;
	  transform: translate(-50%, -50%);
	  opacity:1;
		&:before{
			content: "SCROLL";
			position:absolute;
			font-size:14px;
			letter-spacing:0.25em;
			top:-30px;
			left:-1.5em;
		}
		&:after {
		background-color: $white;
		border-radius: 100%;
		content: "";
		position: absolute;
		top: 5px;
		left: 50%;
		margin-left: -3px;
		width: 6px;
		height: 6px;
		opacity: 1;
		transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
		animation: mousescroll 1.5s -1s cubic-bezier(0.680, -0.550, 0.265, 1.550) infinite;
	  }
	}
	
	@keyframes mousescroll {
  0%, 20% {
    transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
  }
  10% {
    transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
    opacity: 0.01;
  }
}
	

}


/***** Intro *****/


.intro{
	.flex-video {
		opacity: 0;
		visibility: hidden;
		iframe{
			border:0;
			outline:none;
			box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
		}
	}
	.heroBg {
  	position: absolute;
  	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(/assets/media/videos/hero/hero-poster.jpg) no-repeat center;
    background-size: cover;
	} 
	.heroBg-small {
	position: absolute;
  	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(/assets/media/videos/hero/bg-video-sml.jpg) no-repeat center;
    background-size: cover;
	}
	video {box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);}
	.small-10 {
	max-width:1600px;
	position: relative;
	z-index: 1;}
	#vidsync {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    width: 110%;
	    height: 110%;
	    filter: blur(10px) brightness(.5);
		    
	}

}
.pt-page-current .flex-video {
	opacity: 1;
	visibility: visible;
}



/***** Quote *****/

.quote {
	background:$white;
	blockquote {
	position:relative;
	font-weight:800;
	font-size: 2em;
    line-height: 1;
    text-align: left;
	color:$red;
	border: none;
	Z-index:2;
	letter-spacing: -0.05em;
	@media (min-width: 768px) {font-size: 2.5em;}
	@media (min-width: 1024px) {font-size: 3em;}
	&:before {
		content: ' ';
  		position: absolute; 
		width: 100px;
		height: 100px;
		top: -60px;
    	left: 25%;
    	background: url(/assets/img/quote-mark.svg) no-repeat center;
    	background-size: cover;
    	z-index: -1;
		@media (min-width: 768px) {width: 250px;height: 250px;top: -150px;left: -150px;}
		}
	}
	cite {
		font-weight: 800;
		color: $black;
		font-size: 1.5em;
		float: right;
		line-height: 1;
		text-align: left;
		font-style: normal;
    	letter-spacing: -0.05em;
		margin: 1em 0 0 1em;
		span{
			font-family: "acumin-pro-condensed", sans-serif;
			font-size: 0.65em;
			letter-spacing: 0.25em;
			text-transform: uppercase;
			color: #8a8a8a;
			width: 100%;
			display: block;
			text-align: left;
			font-weight: 400;
			margin: 0.5em 0 0 1.5em;
		}
	}
	a {
	font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    color: $dark-gray;
    margin: 0 0 0 2.25em;
    padding: 0;
	}
}



/***** Video Compare *****/
.vid-compare{
	background:$white;
	display:flex;
	align-items: center;
	justify-content: center;
	.video-app {
		background: $white;
		margin:0 auto;
		position:relative; 
		height:0; 
		padding-bottom: 100%; 
		max-width:1600px;
		overflow: hidden;
		@media (min-width: 1024px) {padding-bottom: 50%; margin-top: -5%;}
		
		.vid-buttons{
			position: fixed;
			bottom: 0;
			margin: 0 auto;
			background: $black;
			width: 100%;
    		left: 0;
			.column {padding:0;}
			.button-group {margin-bottom:0;}
			
			button {
				font-family: "acumin-pro-condensed", sans-serif;
				font-weight: 800;
				font-size:1.15em;
				letter-spacing:0.25em;
    			text-transform: uppercase;
				margin:0;
				background:darken($dark-gray, 40%);
				margin: 0;
				border: dashed 1px rgba(255,255,255,0.2);
				margin-right: -1px;
				&:hover {background:darken($dark-gray, 20%);}
				&:focus{box-shadow:none;}
			&.active {background: $red; }
			.left-button {float: left;min-width: 100px;margin: 0 auto; }
			.right-button {float: right;min-width: 100px;margin: 0 auto; }
  			}
		}
	}
}




/***** Stats *****/

.stats {
	@include blueprint-background();
	.columns {
		text-align:left;
		overflow:hidden;
		position:relative;
		z-index: 9999;
		&.stat {
			font-size:1em;
			div{
			width:auto;
			margin: 5em auto 3em;
			@media (min-width: 640px) {width: 60%; padding-left: 1.5em; border-left: solid 5px darken($red,10%);margin: 2em auto;}
			@media (min-width: 1024px) {font-size:1.10em}
			@media (min-width: 1600px) {font-size:1.25em}
			h2{
			font-family: "acumin-pro", sans-serif;
			font-weight: 800;
			font-size: 6em;
			line-height: 0.7;
			text-align: left;
			letter-spacing: -0.5rem;
			margin:-0.1em 0.2em 0.1em 0;
			display: block;
			width:100%;
			@media (min-width: 768px) {display: inline-block;width:auto;}
			}
			h4{
				font-family: "acumin-pro-condensed", sans-serif;
				font-size: 2em;
				letter-spacing: 0.25em;
				text-transform: uppercase;
				line-height:0.8;
				color:$red;
				text-align: left;
				font-weight: 800;
				margin: 0;
				display: inline-block;
			}
			p { margin: 1em 0 0; color:$medium-gray;}
				}
			}
	
		&.video{ 
			padding:0; position:relative; height:100%;
			@media (min-width: 640px) {height:100vh;@include border();}
			video {width: 100%; 
				@media (min-width: 375px) {width:104%; margin-left:-2%}
				@media (min-width: 414px) {width:110%; margin-left:-5%}
				@media (min-width: 640px) {
					height: auto;
					width:auto;
					position: absolute;
					left: 50%;
					top: 50%;
					margin:0;
					min-width: 100%;
					min-height: 100%;
					transform: translate(-50%,-50%);
					z-index: 0;
					}
				}
			}
		}
	}



/***** Jump Facts *****/

.jumpfacts {
	@include blueprint-background();
	&.row {
	padding-top: 30px;
    padding-bottom: 60px;
		padding-right:30px;
	@media (min-width: 1024px) {margin: 0 auto; padding: 0 5em;}
	.columns { border-left:solid 5px $red; margin-left:1.5em; padding-left:1.5em; display:flex; align-items: center;
		@media (min-width:640px) {border-left:none; padding-left:0; margin-left:0; display:block;}
		
		.info {
			@media (min-width:640px) {border-left:solid 5px $red; margin-left:1.5em; padding-left:1.5em;}
		}
		
		}
	}
	svg {margin-bottom:1.25em; display:none;
		@media (min-width: 375px) {display: inline-block; width: 100%; margin-right:0.5em;}
		@media (min-width: 640px) {display: inline-block; width: 60%; max-width:14em; float: none; margin-right:0;}
		@media (min-width: 1200px) {width:90%; max-width:90%}
	}
	.outline {
		fill: transparent;
  		stroke-dasharray: 10;
  		stroke-dashoffset: 100;
  		animation: dash 3s linear forwards;
	}
	@keyframes dash {
		  0% {
			stroke-dashoffset: 100;
			fill: transparent;
		  }
		  75% {
			stroke-dashoffset: 0;
			fill: transparent;
		  }
		  100% {
			stroke-dashoffset: 0;
			fill: #333;
		  }
		}
	@keyframes hideshow {
		  0% { opacity: 0; }
		  75% { opacity: 0; }
		  100% { opacity: 1; }
		}
	h3{display:inline-block;margin-bottom:0; text-transform: uppercase;
	@media (min-width:640px) {display:block;width:100%;line-height: 1; margin-bottom: 0.5em;}
	}
	p{ margin-bottom:0;font-size:90%;}
}




/***** 3D Info Callouts *****/

.info-card {
	&:before{
		content:"";
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		z-index:-1;
		@include blueprint-background();
		opacity:0.8;
	}
	position:relative;
	padding: 1.75em 2em 1em 2.5em;
    margin:2em;
	max-width: 25em;
    font-size: 13px;
	border: solid 1px rgba(230, 6, 5, 0.5) ;
	box-shadow: 0 0 15px 0 rgba(230,6,5,0.5);
	background:rgba(230, 6, 5, 0.075) ;
	h3 {
		font-size: 1.5em;
    	text-transform: uppercase;
	}
}



/***** Timeline *****/

.firsts {
	background: url(/assets/img/bg-black-ice-sml.jpg) $black no-repeat right center;
    background-size: cover;
	padding:0;
	@media (min-width: 768px) {background: url(/assets/img/bg-black-ice.jpg) no-repeat right center; background-size: cover;}
	.title {font-size:1em; width:100%; margin-bottom:2em; border-bottom: 5px $red solid; padding-bottom: 1em;
		@media (min-width:414px) {font-size: 1.5em;}
		
		@media (min-width:1024px) {
			border-bottom: none; 
			padding-bottom: 0;
			position: absolute;
			font-size: 1.5vw;
			right: 5%;
			width: auto;
			top: 30%;
		}
		@media (min-width:1920px) {left: 175px;font-size: 1.6em;}
		
		span{font-weight:800; text-transform:uppercase; text-align:left;display: block; line-height:1;letter-spacing:-0.05em;
			&:nth-child(1){font-size:3.4em}
			&:nth-child(2){font-family:"acumin-pro-condensed", sans-serif; font-size:0.95em; font-weight:400; letter-spacing:0.3em;margin-left:0.5em;}
			&:nth-child(3){font-size:2.8em; line-height:0.8;}
		}
	}
	.first {position:relative;}
	.timeline {
		border-left:1px solid $white;
		margin-left:1em;
		.timeline-block {
		font-size: 0.8em;
		margin-bottom:1.5em;
		position:relative;
		padding-left:20px;
		@media (min-width:414px) {font-size: 1em; padding-left:30px;}
		@media (min-width:1600px) {font-size: 1.2em;padding-left:40px;}
		&:before{
			content:"";
			display:block;
			border:2px solid $white;
			background:$red;
			border-radius:50%;
			width:20px;
			height:20px;
			position:absolute;
			left:-11px;
			top: 15px;
			@media (min-width:414px) {top:20px}
			@media (min-width:768px) {width: 24px; height: 24px; left: -13px; top: 25px;}
		}
		h3{
			font-family: "acumin-pro", sans-serif;
   		 	text-transform: uppercase;
    		font-size: 3.5em;
    		letter-spacing: -0.075em;
			line-height: 0.6;
			}
		.timeline-info{
				display:inline-block;
			    line-height: 1;
			.name{
				font-family: "acumin-pro-condensed", sans-serif;
				text-transform: uppercase;
				font-size: 1.2em;
			}
			.event{}
		}
		.year{
			font-weight:800;
			font-size:3em;
			color:$red;
			line-height: 0.6;
			letter-spacing: -0.075em;
			display: inline-block;
		}
		img{
			display: inline-block;
			vertical-align: baseline;
			max-height:2em;
			}
		}
	}

}





/***** Promo *****/

.promo {
	h2 {text-transform:uppercase;}
	img{margin:1em 0 2em}
	.button {
		display:inline-block;
		font-family: "acumin-pro-condensed", sans-serif;
		font-weight: 800;
		font-size:1em;
		letter-spacing:0.25em;
		text-transform: uppercase;
		margin:0 1em 1.5em;
		background:darken($red, 10%);
		border: dashed 1px rgba(255,255,255,0.2);
		margin-right: -1px;
		&:hover {background:$red; color:$white;}
		&:focus{box-shadow:none;}
	}
	a {
		font-size:80%; 
		color:$white;
		&:hover {color:$red;}
	}
}

/********************
//page content animations
********************/

// .will-reveal.show {
// 	visibility: visible !important;
// 	opacity: 1 !important;
// }

.will-move {
	visibility: hidden;
	opacity: 0;
}

.will-reveal {
	visibility: hidden;
	opacity: 0;
    transform: scale(0.7);
}

.fromTop {transform-origin: top left;}
.fromRight {transform-origin: center right;}
.fromBottom {transform-origin: center bottom;}
.fromLeft {transform-origin: center left;}


/***** react specific *****/

//react portal
.in-react, .out-react {
  position: absolute;
  width: 100%;
  height: 100%;
}

.out-react {
  z-index: 10;
}

.in-react {
  z-index: 20;
}

//hide controls when iphone inline video is enabled
.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
   opacity: 0;
   pointer-events: none;
   width: 5px;
}

