/* -------------------------------- 

Pages

-------------------------------- */

.pt-page {
  .page-wrapper {
    width: 100%;
    height: 100%;
    .page-content {
      //hide at first to fade in
      visibility: hidden;
      overflow: hidden;
    }
  }

  
  .page1 {
    background: transparent;
  }

  .page2 {
    background: transparent;
  }

  .page3 {
    background: transparent;
  }

  .page4 {
    background: transparent;
  }

  .page5 {
    background: transparent;
  }

}



/* -------------------------------- 

page transitions

-------------------------------- */

.pt-perspective {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1200px;
  transform-style: preserve-3d;
}

.pt-page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.pt-page-current,
.no-js .pt-page {
  visibility: visible;
}

.no-js body {
  overflow: auto;
}

.pt-page-ontop {
  z-index: 999;
}
//tha back of the page transition
.page-wrapper-outer {
  .psychology, .jumps, .perspective, .quint {
    background: $white;
  }
  .pursuit {
    background: grey;
  }
  .index, .height, .hangtime, .rotation, .stages  {
    background: $black;
  }

}

//animations------------/

.pt-page-moveToTop {
  -webkit-animation: moveToTop .6s ease both;
  animation: moveToTop .6s ease both;
}

.pt-page-moveToBottom {
  -webkit-animation: moveToBottom .6s ease both;
  animation: moveToBottom .6s ease both;
}

.pt-page-scaleUp {
  -webkit-animation: scaleUp .7s ease both;
  animation: scaleUp .7s ease both;
}

@keyframes moveToTop {
  from { }
  to { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
}

@keyframes moveToBottom {
  from { }
  to { -webkit-transform: translateY(100%); transform: translateY(100%); }
}

@keyframes scaleUp {
  from { opacity: 0; -webkit-transform: scale(.8); transform: scale(.8); }
}
