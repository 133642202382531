/* -------------------------------- 

three.js scene

-------------------------------- */

#debug {
  position: absolute;
  top: 20px;
  right: 100px;
}

.scene-container {
  box-sizing: border-box;
  position: absolute;
  height: 100vh;
  width: 100vw;

  //hide at first
  visibility: hidden;
  opacity: 0;

  z-index: 10;
  background: #000;
}

//zoom monitor
.zoom-monitor {
  width: 10px;
  height: 38px;
  padding: 2px;
  position: absolute;
  top: 23px;
  right: 30px;
  overflow: hidden;
  .zoom-mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 38px;
    .inner {
      width: 100%;
      background: #A3D5DC;
      z-index: 110;
      min-height: 1px;
      margin: 2px 0;
    }
  }

}

//popovers for meshes
.popover {
  position:absolute;
  z-index: 10;
  top: -1000px;
  left:0; /* you should change left and top */
}