/* -------------------------------- 

Navigation trigger - visible on small devices only

-------------------------------- */

// .cd-nav-trigger {
//   display: block;
//   position: fixed;
//   z-index: 2000;
//   bottom: 30px;
//   //right: 5%;
//   right: 30px;
//   height: 44px;
//   width: 44px;
//   border-radius: 0.25em;
//   background: rgba($color-2, 0.9);

//   /* reset button style */
//   cursor: pointer;
//   appearance: none;
//   border: none;
//   outline: none;

//   span {
//       /* central dot */
//       position: absolute;
//       height: 4px;
//       width: 4px;
//       background-color: $color-1;
//       border-radius: 50%;
//       left: 50%;
//       top: 50%;
//       bottom: auto;
//       right: auto;
//       transform: translateX(-50%) translateY(-50%);

//     &::before, &::after {
//       /* bottom and top dots */
//       content: '';
//       position: absolute;
//       left: 0;
//       height: 100%;
//       width: 100%;
//       background-color: $color-1;
//       border-radius: inherit;
//     }

//     &::before {
//       top: -9px;
//     }

//     &::after {
//       bottom: -9px;
//     }
//   }


//   @include MQ(M) {
//     display: none;
//   }
// }


// /* -------------------------------- 

// Vertical navigation

// -------------------------------- */

// .cd-vertical-nav {

//   // width: calc(100% - 60px);
//   // height: calc(100% - 60px);
//   width: 100%;
//   height: 100%;


//   // margin: 30px;
//   // ul {
//   //   height: 100%;
//   //   display: flex;
//   //   flex: 1;
//   //   flex-direction: column;
//   //   li {
//   //     flex-grow: 1;
//   //     display: flex;
//   //     flex-direction: column;
//   //     div:nth-child(3n) {
//   //       flex-grow: 1;
//   //       display: flex;
//   //       flex-direction: column;
//   //       border-bottom: 1px solid rgba($color-1, 0.1);
//   //       a {
//   //         flex-grow: 1;
//   //       }
//   //     }
//   //   }
//   // }

//     ul {
//     height: 100%;
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     li {
//       flex-grow: 1;
//       display: flex;
//       flex-direction: column;
//       div:nth-child(3n) {
//         > div {
//           flex-grow: 1;
//           display: flex;
//           flex-direction: column;
//         }
//         flex-grow: 1;
//         display: flex;
//         flex-direction: column;
//         border-bottom: 1px solid rgba($color-1, 0.1);
//         a {
//           flex-grow: 1;
//         }
//       }
//     }
//   }

//   //right: 5%;
//   //bottom: 30px;
//   //width: 90%;
//   //max-width: 400px;
//   //max-height: 90%;
  
//   position: fixed;
//   z-index: 1000;
//   overflow-y: auto;
//   transform: scale(0);
//   transform-origin: right bottom;
//   transition: transform 0.2s;
//   border-radius: 0.25em;
//   background-color: rgba($color-2, 0.9);

//   a {
//     display: block;
//     padding: 1em;
//     color: $color-1;
//     font-weight: bold;

//     &.active {
//       color: $color-4;
//     }
//   }
  
//   &.open {
//     transform: scale(1);
//     -webkit-overflow-scrolling: touch;
//   }

//   &.open + .cd-nav-trigger {
//     background-color: transparent;
    
//     span {
//       background-color: rgba($color-1, 0);  
//     }

//     span::before, span::after {
//       /* transform dots in 'X' icon */
//       height: 3px;
//       width: 20px;
//       border-radius: 0;
//       left: -8px;
//     }

//     span::before {
//       transform: rotate(45deg);
//       top: 1px;
//     }

//     span::after {
//       transform: rotate(135deg);
//       bottom: 0;
//     }
//   }

//   @include MQ(M) {



//     /* reset style */
//     margin: 0;
//     right: 0;
//     top: 0;
//     bottom: auto;
//     height: 100vh;
//     width: 90px;
//     max-width: none;
//     max-height: none;
//     transform: scale(1);
//     background-color: transparent;
//     /* vertically align its content*/
//     display: table;

//     &::before {
//       /* this is the navigation background */
//       content: '';
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       background: rgba($color-3, .8);
//       transform: translateX(100%);
//       transition: transform .4s;
//     }

//     .no-touch &:hover::before, 
//     .touch &::before {
//       transform: translateX(0);
//     }

//     ul {
//       /* vertically align <ul> inside <nav>*/
//       display: table-cell;
//       vertical-align: middle;
//       text-align: center;
//       li {
//           display: list-item;
//           div:nth-child(3n) {
//             display: block;
//             border: 0 none;
//               > div {
//               display: block;
//             }
//           }
//         }
//     }


//     a {
//       position: relative;
//       padding: 3em 0 0;
//       margin: 1.4em auto;
//       border-bottom: none;
//       font-size: 1.2rem;
//       color: $color-2;

//       &.active {
//         color: $color-2;
//       }

//       &::before, &::after {
//         /* used to create the filled circle and the background icon */
//         content: '';
//         position: absolute;
//         left: 50%;
//         /* Force Hardware Acceleration */
//         backface-visibility : hidden;
//         transform : translateZ(0);
//       }

//       &::before {
//         /* filled circle */
//         top: 0;
//         height: 32px;
//         width: 32px;
//         border-radius: 50%;
//         background: $color-2;
//         transform : translateX(-50%) scale(0.25);
//         transition : transform .4s, background-color 0.2s;
//       }

//       &::after {
//         /* icon */
//         opacity: 0;
//         top: 8px;
//         height: 16px;
//         width: 16px;
//         background: url(../img/cd-nav-icons.svg) no-repeat;
//         transform : translateX(-50%) scale(0);
//         transition : transform 0.4s 0s, opacity 0.1s 0.3s;
//       }

//       &.active::before, 
//       .no-touch &:hover::before {
//         background-color: $color-4;
//       }
//     }

//     li:first-of-type a::after {
//       /* change icon using image sprites */
//       background-position: 0px -48px;
//       /* fix bug with transitions - set scale(0.01) rather than scale(0) */
//       transform : translateX(-50%) translateY(4.5em) scale(0.01);
//     }

//     li:first-of-type a::before {
//       transform : translateX(-50%) translateY(4.5em) scale(0.25);
//     }

//     li:nth-of-type(2) a::after {
//       background-position: 0px -32px;
//       transform : translateX(-50%) translateY(1.5em) scale(0.01);
//     }

//     li:nth-of-type(2) a::before {
//       transform : translateX(-50%) translateY(1.5em) scale(0.25);
//     }

//     li:nth-of-type(3) a::after {
//       background-position: 0px -16px;
//       transform : translateX(-50%) translateY(-1.5em) scale(0.01);
//     }

//     li:nth-of-type(3) a::before {
//       transform : translateX(-50%) translateY(-1.5em) scale(0.25);
//     }

//     li:nth-of-type(4) a::after {
//       background-position: 0px 0px;
//       transform : translateX(-50%) translateY(-4.5em) scale(0.01);
//     }

//     li:nth-of-type(4) a::before {
//       transform : translateX(-50%) translateY(-4.5em) scale(0.25);
//     }

//     li:nth-of-type(5) a::after {
//       background-position: 0px 0px;
//       transform : translateX(-50%) translateY(-7.5em) scale(0.01);
//     }

//     li:nth-of-type(5) a::before {
//       transform : translateX(-50%) translateY(-7.5em) scale(0.25);
//     }

//     li:nth-of-type(6) a::after {
//       background-position: 0px 0px;
//       transform : translateX(-50%) translateY(-10.5em) scale(0.01);
//     }

//     li:nth-of-type(6) a::before {
//       transform : translateX(-50%) translateY(-10.5em) scale(0.25);
//     }

//     .label {
//       display: block;
//       /* Force Hardware Acceleration */
//       backface-visibility : hidden;
//       transform : translateZ(0);
//       transform : translateX(100%);
//       transition : transform .4s;
//     }

//     .no-touch &:hover a::before,
//     .no-touch &:hover a::after,
//     .touch & li:nth-of-type(n) a::before,
//     .touch & li:nth-of-type(n) a::after {
//     transform : translateX(-50%) scale(1);
//       /* set a rotation to fix bug on Firefox - http://gielberkers.com/how-to-fix-shaking-css-transitions-in-firefox/ */
//       -moz-transform : translateX(-50%) scale(1) rotate(0.02deg);
//     }

//     .no-touch &:hover a::after, 
//     .touch & a::after {
//       opacity: 1;
//       transition : transform 0.4s, opacity 0s;
//     }

//     .no-touch &:hover .label,
//     .touch & .label {
//       transform : translateX(0);
//     }
//   }
// }
